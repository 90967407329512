import {
  CampaignAlert,
  FreeCreditsAlert,
  RuntimeAlert,
} from '@/components/AppchainCard/Alerts';
import { BlockProduction } from '@/components/AppchainCard/BlockProduction';
import { Feed } from '@/components/AppchainCard/Feed';
import { Properties } from '@/components/AppchainCard/Properties';
import { Status } from '@/components/AppchainCard/Status';
import { Tooling } from '@/components/AppchainCard/Tooling';
import { AppchainIconEditor } from '@/components/AppchainIconEditor';
import { AppchainIdenticon } from '@/components/AppchainIdenticon';
import { useManageAppchain } from '@/components/pages/Dashboard/ManageAppchain/state';
import { ManageAppchainTab } from '@/components/pages/Dashboard/ManageAppchain/state/ManageAppchain.constants';
import {
  ChainConfig,
  ChainKey,
  DEMO_APPCHAIN_PARA_ID,
  Explorer,
} from '@/config';
import { useAppchain } from '@/hooks/api/useAppchain';
import { useIsAppchainManagementEnabledFlag } from '@/hooks/flags/useIsAppchainManagementEnabledFlag';
import { useIsCampaignEnabledFlag } from '@/hooks/flags/useIsCampaignEnabledFlag';
import { useIsRuntimeUpgradeEnabledFlag } from '@/hooks/flags/useIsRuntimeUpgradeEnabledFlag';
import {
  AppChainStatus,
  useAppchainConfig,
  useAppchainManager,
  useAppchainStatus,
  useAppchainUrls,
} from '@/hooks/polkadot/appchain';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { useAddress } from '@/state/user';
import { trpc } from '@/utils/trpc';
import {
  Button,
  Group,
  Paper,
  PaperProps,
  Skeleton,
  Stack,
  Title,
  rgba,
  useMantineTheme,
} from '@mantine/core';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { AddToMetamask } from './AddToMetamask';

export interface Props extends PaperProps {
  paraId: number;
  config: ChainConfig;
  name?: string;
  explorers?: Explorer[];
}

export function AppchainCard({
  name,
  paraId,
  config,
  explorers,
  ...others
}: Props) {
  const theme = useMantineTheme();
  const { sm } = useMediaQuery();
  const address = useAddress();

  const urls = useAppchainUrls(paraId, config);
  const appchainConfig = useAppchainConfig(paraId, config);
  const manager = useAppchainManager(paraId, config);
  const router = useRouter();
  const [logoUrl, setLogoUrl] = useState<string | undefined>(undefined);
  const { openAppchainSidebar } = useManageAppchain();

  const { isEnabled: isAppchainManagementEnabled } =
    useIsAppchainManagementEnabledFlag();
  const { isEnabled: isRuntimeUpgradeEnabled } =
    useIsRuntimeUpgradeEnabledFlag();
  const { isEnabled: isCampaignActive } = useIsCampaignEnabledFlag();

  const { appchain, isLoading: isLoadingAppchain } = useAppchain(
    config.key,
    paraId,
  );

  const { isPending, mutateAsync } =
    trpc.appchains.updateItemImage.useMutation();

  const { status, isLoading: isLoadingStatus } = useAppchainStatus(
    paraId,
    config,
  );

  async function updateImage(content: string): Promise<void> {
    const url = await mutateAsync({
      paraId,
      chainKey: config.key,
      iconImageBase64: content,
    });

    setLogoUrl(url);
  }

  if (isLoadingAppchain || !appchain) {
    return <Skeleton mb={15} width={'100%'} height={330} />;
  }

  const isEvm = appchain.isEvm;
  const chainId = parseInt(appchain.evmChainId || '0');
  const appchainName =
    name ||
    (appchain?.chainName?.includes('Frontier Container') ||
    appchain?.chainName?.includes('Simple Container') ||
    !appchain?.chainName
      ? paraId.toString()
      : appchain.chainName);

  const isDemo =
    paraId === DEMO_APPCHAIN_PARA_ID && config.key === ChainKey.Dancebox;
  const isAppchainView = router.route.includes('/appchains/');
  const shouldShowFreeCreditsAlert =
    status === AppChainStatus.Online && !isDemo && !isAppchainView;
  const shouldShowRuntimeAlert =
    status === AppChainStatus.Online &&
    manager === address &&
    isRuntimeUpgradeEnabled;
  const shouldShowCampaignAlert =
    status !== AppChainStatus.Online && isCampaignActive;
  const isAppchainManager = manager === address;

  if (isLoadingStatus) {
    return <Skeleton mb={15} width={'100%'} height={330} />;
  }

  return (
    <Paper
      p={'md'}
      bg={'dark.9'}
      style={{ border: `2px solid var(--mantine-color-dark-6)` }}
      data-testid={`appchain-card-${config.key}-${paraId}`}
      {...others}
    >
      <Group mb={20} justify={'space-between'} gap={'xs'}>
        <Group gap={'xs'} w={{ base: '100%', xs: '40%' }}>
          {isAppchainManager ? (
            <AppchainIconEditor
              onUpdate={updateImage}
              pos={'relative'}
              isPending={isPending}
              IconComponent={
                <AppchainIdenticon
                  paraId={appchain.paraId}
                  logoUrl={logoUrl ?? appchain.logoUrl}
                  size={40}
                />
              }
            />
          ) : (
            <AppchainIdenticon
              paraId={appchain.paraId}
              logoUrl={appchain.logoUrl}
              size={40}
            />
          )}

          <Title order={2} size={20} c={theme.other.colors.orange}>
            {appchainName?.toUpperCase()}
          </Title>
        </Group>

        <Status paraId={paraId} config={config} />

        <Group
          gap={'xs'}
          justify={'end'}
          align={'center'}
          w={{ base: '80%', xs: '40%' }}
        >
          {status === AppChainStatus.Online && (
            <>
              {isEvm && (
                <AddToMetamask
                  isIconOnly
                  name={appchainName}
                  chainId={chainId}
                  explorer={explorers?.[0].url}
                  paraId={paraId}
                  config={config}
                  rpc={urls?.rpc}
                />
              )}
              {address && (
                <Button
                  variant={'light'}
                  size={'sm'}
                  bg={rgba('var(--mantine-color-dark-7)', 0.9)}
                  onClick={() => {
                    openAppchainSidebar({
                      paraId,
                      config,
                      tab: ManageAppchainTab.BlockProduction,
                    });
                  }}
                >
                  {'Top Up'}
                </Button>
              )}
              {isAppchainManagementEnabled && isAppchainManager && (
                <Button
                  variant={'light'}
                  size={'sm'}
                  bg={rgba('var(--mantine-color-dark-7)', 0.9)}
                  onClick={() =>
                    openAppchainSidebar({
                      paraId,
                      config,
                    })
                  }
                >
                  {'Manage'}
                </Button>
              )}
            </>
          )}
        </Group>
      </Group>

      <Stack gap={'xs'}>
        {shouldShowCampaignAlert && <CampaignAlert />}
        {shouldShowFreeCreditsAlert && <FreeCreditsAlert />}
        {shouldShowRuntimeAlert && (
          <RuntimeAlert paraId={paraId} config={config} />
        )}
      </Stack>

      <Group
        mt={15}
        align={'auto'}
        style={{ flexDirection: sm ? 'row' : 'column' }}
      >
        {status === AppChainStatus.Online && (
          <BlockProduction
            w={{ base: 'auto', sm: '35%' }}
            paraId={paraId}
            config={config}
          />
        )}

        <Properties
          paraId={paraId}
          isEvm={isEvm}
          chainId={chainId}
          tokenSymbol={appchainConfig?.asset.originSymbol}
          config={config}
        />
      </Group>

      {status === AppChainStatus.Online && (
        <Group
          mt={15}
          align={'auto'}
          style={{ flexDirection: sm ? 'row' : 'column' }}
        >
          <Tooling urls={urls} explorers={explorers} />

          <Feed paraId={paraId} config={config} />
        </Group>
      )}
    </Paper>
  );
}
