import { ChainKey } from '@/config';
import { AppchainItem } from '@/server/router/appchains';
import { trpc } from '@/utils/trpc';

export function useAppchain(
  chainKey: ChainKey,
  paraId: number,
): {
  appchain: AppchainItem | undefined;
  isLoading: boolean;
} {
  const { data, isLoading } = trpc.appchains.getAppchain.useQuery({
    chainKey,
    paraId,
  });

  return { appchain: data, isLoading };
}
